import React, { useState } from "react";
import H2 from "~/components/Typography/H2";
import LessonLayout from "~/layouts/lesson";
import ContentWrapper from "~/components/content-wrapper";
import TeacherViewWrapper from "~/layouts/teacher-view-wrapper";
import UnorderedList from "~/components/List/Unordered";
import { StudentCard, TeacherCard } from "~/components/content-card";

const Page = () => {
  const [studentFullScreen, setStudentFullScreen] = useState<boolean>(false);
  const [teacherFullScreen, setTeacherFullScreen] = useState<boolean>(false);

  return (
    <LessonLayout
      current_step={4}
      lesson={"Lesson 5"}
      color={"ms"}
      total_count={7}
      module_title="Putting Your Rubric to Work"
    >
      <ContentWrapper>
        <H2 underline>Step 4</H2>

        <TeacherViewWrapper>
          <StudentCard
            fullScreen={studentFullScreen}
            hidden={teacherFullScreen}
            highSchool={false}
            setFullScreen={setStudentFullScreen}
          >
            <p>Were you able to answer your question? How satisfied are you with the answers you found? Are there still things you would like to find out before you are comfortable answering your original question?</p>
            <p>Write 2-3 questions you would ask either to confirm your answer or to resolve any uncertainties you still have.</p>
            <p>How could you get that information?</p>
          </StudentCard>
          <TeacherCard
            fullScreen={teacherFullScreen}
            hidden={studentFullScreen}
            highSchool={false}
            setFullScreen={setTeacherFullScreen}
          >
            <p>After students have scored their information piece, encourage them to reflect on what they have learned using the rubric. Does their opinion about the quality of the resource change after they have scored it using their rubric? Do they feel better prepared to answer the question that they wrote?</p>

            <p>Students are asked to write 2-3 questions that would help them either to confirm the information in their item or to resolve any additional uncertainties that may have arisen. For example, students might ask questions about</p>
            <UnorderedList>
              <li>how the product or treatment is similar to or different from something they have experienced previously,</li>
              <li>the quality of the testing that was done on a product or a treatment,</li>
              <li>whether the information provided really supports the claims that are being made,</li>
              <li>how this product or treatment compares with similar products or treatments,</li>
              <li>whether the product or treatment benefits a wide range of people or just certain groups of people, or</li>
              <li>the side effects or risks of a product or treatment.</li>
            </UnorderedList>

            <p>Students are also asked to think about how they might get answers to their questions. You may want to have a class discussion so students can share their ideas. Some possible ways that students could get answers include the following:</p>
            <UnorderedList>
              <li>conduct additional internet research</li>
              <li>discuss the issue with a doctor, pharmacist, or other health professional</li>
              <li>contact the company to ask for more information and evidence about their claims</li>
            </UnorderedList>

            <p>An optional activity for the class could be contacting a company to ask for more documentation or evidence to support the claims made in their advertisements. This could help students feel more powerful as consumers and about their rights to challenge information that is presented to them.</p>
          </TeacherCard>
        </TeacherViewWrapper>
      </ContentWrapper>
    </LessonLayout>
  )
}

export default Page;

